<template>
    <div>
        <BackOnTop :url="url" />
        <h2>線上DM</h2>
        <div class="baby_wrappper" v-if="!$store.state.isLoading">
            <div
                v-for="(dmInfo, id) in dmList"
                :key="id"
                style="margin-bottom: 10px"
                @click="$router.push(`/dm/detail?id=${dmInfo.id}&from=${$route.query.from}&phone=${$route.query.phone}&name=${$route.query.name}&password=${$route.query.password}&birthday=${$route.query.birthday}&gender=${$route.query.gender}&reg_storeno=${$route.query.reg_storeno}&county=${$route.query.county}&district=${$route.query.district}&address=${$route.query.address}&isSendDM=${$route.query.isSendDM}`)"
            >
                <img class="main-pic" :src="dmInfo.url" />
                <p style="font-weight: bold">{{ dmInfo.title }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import { getDmList } from '@/apis/api.js';

export default {
    name: 'DmIndex',
    components: { BackOnTop },
    data: () => ({
        dmList: [],
        url: '',
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    created() {
        if (this.$route.query.url == 'member') {
            this.url = ''
        } else if(this.$route.query.from === 'member') {
            this.url = {
                path: '/member/info'
            }
        } else if (this.$route.query.from === 'register') {
            this.url = {
                path: '/register?read=true',
                query: this.$route.query,
            }
        } else {
            this.url = '/'
        }
    },
    mounted() {
        const parent = this;
        getDmList().then(function (rsp) {
            console.log(rsp);
            for (let dmInfo of rsp) {
                parent.dmList.push({
                    id: dmInfo.id,
                    url: dmInfo.main_pic.url,
                    title: dmInfo.title,
                });
                parent.$store.state.isLoading = false;
            }
        });
    },
    methods: {},
};
</script>
<style lang="scss" scoped>
.main-pic {
    width: 80%;
    box-shadow: -3px 3px 10px 3px grey;
}
.baby_wrappper {
    padding: 20px 16px;
}
h2 {
    color: $color_main_first;
    font-size: 26px;
    font-weight: bold;
    padding: 12px 16px;
    line-height: 1;
    text-align: left;
}
.list {
    margin-top: 12px;
    padding: 0 16px 70px;
}
a {
    font-size: 16px !important;
    font-weight: 500;
    border-radius: 25px;
    position: fixed;
    left: 50%;
    bottom: 36px;
    transform: translate(-50%, 0);
}
.func {
    list-style: none;

    li {
        margin: 8px 0;
        line-height: 40px;
        text-align: center;
        width: 100%;
        &.active {
            background: rgba(249, 98, 48, 0.15);
        }
    }
}
</style>
